<template>
  <div>
    <!-- form -->

    <status-alert
      :is-success="isSuccess"
      :error-data="errorData"
      :success-msg="successMsg"
    />
    <b-row v-if="clientData">

      <b-col
        md="12"
        lg="12"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
            :to="{ name: 'clients-edit', params: { id: clientData.id} }"
          >
            Edit
          </b-button>
        </div>
        <b-form
          class="p-2"
        >

          <!-- Name -->
          <b-col cols="12">

            <b-form-group
              label="Client name"
              label-for="client-name"
              label-cols-md="4"
            >
              <b-form-input
                id="client-name"
                v-model="clientData.name"
                size="lg"
                plaintext
              />

            </b-form-group>

          </b-col>

          <!--/ Name -->
          <!-- Email -->

          <!-- Name -->
          <b-col cols="12">

            <b-form-group
              label="Email"
              label-for="email"
              label-cols-md="4"
            >
              <b-form-input
                id="email"
                v-model="clientData.email"
                size="lg"
                plaintext
              />

            </b-form-group>

          </b-col>

          <!--/ Email -->
          <!-- billing address -->
          <b-col cols="12">
            <b-form-group
              label="Billing address"
              label-for="billing-address"
              label-cols-md="4"
            >
              <b-form-input
                id="billing-address"
                v-model="clientData.billing_address"
                size="lg"

                plaintext
              />
            </b-form-group>
          </b-col>
          <!--/ billing address-->
          <!-- Contact name -->

          <b-col cols="12">

            <b-form-group
              label="Contact name"
              label-for="contact-name"
              label-cols-md="4"
            >
              <b-form-input
                id="contact-name"
                v-model="clientData.contact_name"
                size="lg"
                plaintext
              />
            </b-form-group>

          </b-col>

          <!--/ contact name-->
          <!-- phone -->

          <b-col cols="12">

            <b-form-group
              label="Phone"
              label-for="phone"
              label-cols-md="4"
            >
              <b-form-input
                id="phone"
                v-model="clientData.phone"
                size="lg"
                plaintext
              />
            </b-form-group>

          </b-col>
          <!--/ phone-->
          <!-- mobile -->

          <b-col cols="12">

            <b-form-group
              label="Mobile"
              label-for="mobile"
              label-cols-md="4"
            >
              <b-form-input
                id="mobile"
                v-model="clientData.mobile"
                size="lg"
                plaintext
              />
            </b-form-group>

          </b-col>
          <!--/ mobile-->
          <!-- website -->

          <b-col cols="12">

            <b-form-group
              label="Website"
              label-for="website"
              label-cols-md="4"
            >
              <b-form-input
                id="website"
                v-model="clientData.website"
                size="lg"
                plaintext
              />
            </b-form-group>

          </b-col>

          <!--/ website-->
          <!-- Tax number -->

          <b-col cols="12">

            <b-form-group
              label="Tax number"
              label-for="tax-number"
              label-cols-md="4"
            >
              <b-form-input
                id="tax-number"
                v-model="clientData.tax_number"
                size="lg"
                plaintext
              />
            </b-form-group>

          </b-col>
          <!--/ tax number-->
          <!-- Notes -->
          <b-col cols="12">

            <b-form-group
              label="Notes"
              label-for="notes"
              label-cols-md="4"
            >
              <b-form-input
                id="notes"
                v-model="clientData.notes"
                size="lg"
                plaintext
              />
            </b-form-group>

          </b-col>
        <!--/ Notes-->

        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { required, alphaNum, email } from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Client Updated',
      errorData: null,

    }
  },
  created() {

  },
  methods: {

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
