<template>
  <b-modal
    id="paidModal"
    ref="paidModal"
    title="Invoice mark as paid"
    ok-only
    ok-title="Done"

    @ok="saveData"
  >
    <b-overlay
      id="overlay-background"
      :show="pageIsLoading"
      variant="light"
      opacity="0.85"
      blur="5px"
      rounded="sm"
    >

      <template #overlay>
        <b-card

          class="text-center"
        >

          <h2>
            <b-spinner

              class="mr-1"
              label="Loading"
            />
            {{ overlayMsg }}
          </h2>

        </b-card>
      </template>
      <div v-if="paymentMethods && invoiceData">
        <!-- form -->

        <status-alert
          :is-success="isSuccess"
          :error-data="errorData"
          :success-msg="successMsg"
        />
        <b-row>
          <b-col
            md="12"
            lg="12"
          >
            <h3 class="font-weight-bolder mt-2 ml-3">
              {{ invoiceData.content.currency_code }} {{ $staticParams.formatCurrency(paidData.paid_amount) }}
            </h3>
            <validation-observer

              ref="formPaid"
            > <b-form
              class="p-2"
            >

              <validation-provider
                #default="{errors}"
                :name="$t('Payment date')"
                rules="required"
              >
                <!-- Name -->
                <b-col
                  cols="12"
                  class="p-0"
                >

                  <b-form-group
                    label="Date"
                    label-for="payment-date"

                    class="font-weight-bolder"
                  >
                    <b-form-datepicker
                      id="payment-date"
                      v-model="paidData.paid_date"
                      size="lg"
                      local="en"
                      today-variant="danger"
                      :state="errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider
                #default="{errors}"
                :name="$t('Payment method')"
                rules=""
              >
                <!-- Name -->
                <b-col cols="12">
                  <b-form-group

                    label-for="client-name"
                  >
                    <h5>Payment method (optional)</h5>
                    <b-row>

                      <b-col class="d-flex flex-column">

                        <b-form-radio
                          v-for="item,index in paymentMethods"
                          :key="index"
                          v-model="paidData.paid_type"
                          class="mt-1"

                          :value="item.id"
                        >
                          <h4 class="font-weight-bolder">
                            {{ item.name }}
                          </h4>
                        </b-form-radio>
                      </b-col>
                    </b-row>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <!--/ Name -->
              <b-col cols="12">
                <b-form-group

                  label-for="paid-details"
                >
                  <b-form-textarea
                    id="paid-details"
                    v-model="paidData.description"
                    size="lg"
                    rows="3"

                    placeholder="Add details..."
                  />
                </b-form-group>
              </b-col>
            </b-form>
            </validation-observer>
          </b-col>

        </b-row>

      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BRow, BCol, BFormInvalidFeedback, BModal, BFormTextarea, BFormRadio, BOverlay, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import invoiceCalculations from '@core/components/invoice-themes/calculations'
import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    BForm,
    BFormGroup,
    BRow,
    BFormTextarea,
    BOverlay,
    BFormRadio,
    BFormDatepicker,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      overlayMsg: 'Loading...',
      pageIsLoading: true,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Discount updated',
      errorData: null,
      blankPaidData: {
        invoice_id: 0,
        paid_amount: 0,
        paid_type: 0,
        description: '',
        paid_date: null,

      },
      paidData: null,
      invoiceData: null,
      paymentMethods: null,
    }
  },
  watch: {
  },
  created() {
    this.fetchPaymentMethods()
    this.resetForm()
  },
  methods: {
    calculateTotals() {
      return invoiceCalculations.calculateTotals(this.invoiceData).content.totals
    },
    fetchPaymentMethods() {
      store
        .dispatch('app-invoice/fetchPaymentTypes')
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.paymentMethods = response.data.data
          this.pageIsLoading = false
        })
        .catch(error => {
          this.pageIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    showEditor(invoiceData) {
      const today = this.$moment()
      this.blankPaidData.paid_date = today.format('YYYY-MM-DD')
      this.isSuccess = false
      this.errorData = null
      this.resetForm()

      this.invoiceData = invoiceData
      this.paidData.invoice_id = invoiceData.id

      this.paidData.paid_amount = this.calculateTotals().total
      this.$refs.paidModal.show()
    },
    closeEditor() {
      this.$refs.paidModal.hide()
    },

    cancel() {
      this.$emit('closed')
    },
    deleteData() {

    },
    saveData(modalEvt) {
      modalEvt.preventDefault()
      this.$refs.formPaid.validate().then(success => {
        if (success) {
          const formData = JSON.parse(JSON.stringify(this.blankPaidData))
          if (formData.paid_type === 0) {
            formData.paid_type = null
          }
          store
            .dispatch('app-invoice/markAsPaid', this.paidData)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.errorData = null
              this.$emit('paided', this.paidData)
              this.closeEditor()
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.errorData = error.response.data
              }
            })
        }
      })
    },
    resetForm() {
      this.paidData = JSON.parse(JSON.stringify(this.blankPaidData))
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
