<template>
  <b-card v-if="clientData">
    <!-- form -->
    <h2>{{ clientData.name }}</h2>
    <hr>
    <b-tabs>
      <b-tab title="Client Invoices">
        <client-invoices

          :client-id="clientData.id"
        />
      </b-tab>
      <b-tab title="Client Info">
        <client-info :client-data="clientData" />

      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  onUnmounted,
} from '@vue/composition-api'
import clientStoreModule from '../clientStoreModule'
import clientInfo from './ClientInfo.vue'
import clientInvoices from './ClientInvoices.vue'

export default {
  components: {
    BCard,
    clientInfo,
    BTabs,
    clientInvoices,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      clientData: null,
    }
  },
  created() {
    let clientId = 0

    if (this.$router.currentRoute.params.id) {
      clientId = this.$router.currentRoute.params.id
      this.fetchClient(clientId)
    }
  },
  methods: {
    fetchClient(id) {
      store
        .dispatch('app-client/fetchSingleClient', { id })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.clientData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
