<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-overlay
        id="overlay-background"
        :show="pageIsLoading"
        variant="light"
        opacity="0.85"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <b-card

            class="text-center"
          >

            <h2>
              <b-spinner

                class="mr-1"
                label="Loading"
              />
              {{ overlayMsg }}
            </h2>

          </b-card>
        </template>
        <mark-as-paid-component

          ref="markAsPaidComponent"
        />

        <div

          class="m-2"
        >
          <vue-html2pdf
            ref="html2Pdf"
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1100"
            filename="invoice"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"

            pdf-content-width="800px"

            @startPagination="pdfHasStartedGeneration()"
            @hasPaginated="pdfHasGenerated($event)"
          >
            <section slot="pdf-content">
              <invoice-preview
                v-if="showPrint"
                :invoice-data="selectedInvoice"
              />
            </section>
          </vue-html2pdf>
          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            />

            <!-- Search -->
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex justify-content-between align-items-center">
                <b-button-group
                  class="ml-1 mb-1"
                  size="lg"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="filterType=='unpaid'?'primary':'outline-primary'"
                    @click="filterType='unpaid'"
                  >
                    Unpaid
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="filterType=='paid'?'primary':'outline-primary'"
                    @click="filterType='paid'"
                  >
                    Paid
                  </b-button>

                </b-button-group>

              </div>
            </b-col>
          </b-row>
          <hr>
        </div>
        <div v-show="totalInvoices>0">

          <b-table

            ref="refInvoiceListTable"
            class="position-relative"
            :items="fetchTickets"
            responsive
            :fields="tableColumns"
            primary-key="id"
            select-mode="single"
            :sort-by.sync="sortBy"
            striped
            hover
            show-empty
            :sort-desc.sync="isSortDirDesc"
            selectable
            empty-text="No matching records found"
            @row-selected="onRowSelected"
          >
            <template #cell(name)="data">
              <span class="font-weight-bold d-block text-nowrap">{{ data.item.name }}</span>
            </template>
            <template #cell(content.billing)="data">
              <span v-if="!data.item.content.billing"> [Invoice not selected]</span>
              <span v-else> {{ data.item.content.billing.name }}</span>
            </template>
            <template #cell(content.invoice_date)="data">
              <span> {{ $format_date(data.item.content.invoice_date) }}</span>

            </template>
            <template #cell(content.due_date)="data">
              <span> {{ $format_date(data.item.content.due_date) }}</span>

            </template>
            <template #cell(content.paid_date)="data">
              <span> {{ $format_date(data.item.content.paid_date) }}</span>

            </template>
            <template #cell(total)="data">
              <span> {{ data.item.content.currency_code }} {{ $staticParams.formatCurrency(calculateTotals(data.item).total) }}</span>

            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="filterType=='unpaid'"
                  variant="primary"
                  @click="markAsPaid(data.item)"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('Mark as paid') }}</span>
                </b-dropdown-item>
                <hr>
                <b-dropdown-item

                  variant="primary"
                  :to="{ name: 'invoices-edit', params: { id: data.item.id,activeTab:'send' } }"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('Email') }}</span>
                </b-dropdown-item>
                <b-dropdown-item

                  variant="primary"
                  @click="toPdf(data.item)"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('Print') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  variant="danger"
                  @click="deleteData(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                  <span class="align-middle ml-50 font-weight-bolder">{{ $t('Delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalInvoices"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>

        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BDropdown, BDropdownItem, BPagination, BButton, BOverlay, BSpinner, BButtonGroup,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, watch, computed,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import invoiceCalculations from '@core/components/invoice-themes/calculations'
import invoicePreview from '@core/components/invoice-themes/Template.vue'
import VueHtml2pdf from 'vue-html2pdf'
import markAsPaidComponent from '../../invoice/MarkAsPaid.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButtonGroup,
    VueHtml2pdf,
    BTable,
    BDropdown,
    invoicePreview,
    BDropdownItem,
    markAsPaidComponent,
    BPagination,
    BButton,
    BOverlay,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedInvoice: null,
      showPrint: false,
      overlayMsg: 'Loading...',
    }
  },
  watch: {
    filterType() {
      this.$refs.refInvoiceListTable.refresh()
    },
  },
  methods: {
    pdfHasStartedGeneration() {

    },
    toPdf(invoiceData) {
      this.overlayMsg = 'Printing...'
      this.pageIsLoading = true
      this.selectedInvoice = invoiceData
      this.showPrint = true

      setTimeout(() => this.$refs.html2Pdf.generatePdf(), 3000)
    },
    pdfHasGenerated() {
      setTimeout(() => {
        this.showPrint = false
        this.selectedInvoice = false
        this.pageIsLoading = false
        this.overlayMsg = 'Loading...'
      }, 3000)
    },
    markAsPaid(invoiceData) {
      this.$refs.markAsPaidComponent.showEditor(invoiceData)
    },
    calculateTotals(invoiceData) {
      return invoiceCalculations.calculateTotals(invoiceData).content.totals
    },
    onRowSelected(items) {
      this.$router.push({ name: 'invoices-edit', params: { id: items[0].id } })
    },
    deleteData(id) {
      this.$swal({
        title: this.$t('Are you sure ?'),
        text: this.$t('You are about to delete a invoice. This action cannot be undone'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, delete now'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-invoice/deleteInvoice', { id })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Invoice was deleted',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$refs.refInvoiceListTable.refresh()
            })
            .catch(() => {

            })
        }
      })
    },
  },
  setup(props) {
    const toast = useToast()
    const filterType = ref('unpaid')
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const searchQuery = ref('')
    const totalInvoices = ref(0)
    const refInvoiceListTable = ref(null)
    const currentPage = ref(1)
    const pageIsLoading = ref(true)
    const sortBy = ref('content.invoice_date')
    const isSortDirDesc = ref(true)
    const unpaidTableColumns = [
      {
        key: 'content.invoice_number', label: 'Number', sortable: true,
      },
      { key: 'content.billing', label: 'INVOICE', sortable: true },
      { key: 'content.invoice_date', label: 'Date', sortable: true },
      { key: 'content.due_date', label: 'Due date', sortable: true },
      { key: 'total', label: 'Total', sortable: false },

      {
 key: 'actions', label: 'ACTIONS', thClass: 'text-right', tdClass: 'text-right',
},
    ]
    const paidTableColumns = [
      {
        key: 'content.invoice_number', label: 'Number', sortable: true,
      },
      { key: 'content.billing', label: 'INVOICE', sortable: true },
      { key: 'content.invoice_date', label: 'Date', sortable: true },
      { key: 'content.paid_date', label: 'PAID DATE', sortable: true },
      { key: 'total', label: 'Total', sortable: false },

      {
 key: 'actions', label: 'ACTIONS', thClass: 'text-right', tdClass: 'text-right',
},
    ]
    const tableColumns = ref(unpaidTableColumns)

    const refetchData = () => {
      refInvoiceListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalInvoices.value,
      }
    })
    const fetchTickets = (ctx, callback) => {
      pageIsLoading.value = true
      store
        .dispatch('app-invoice/fetchInvoices', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          filterType: filterType.value,
          client_id: props.clientId,
        })
        .then(response => {
          if (filterType.value === 'paid') {
            tableColumns.value = paidTableColumns
          } else if (filterType.value === 'unpaid') {
            tableColumns.value = unpaidTableColumns
          }
          pageIsLoading.value = false
          const { data } = response.data
          const { total } = response.data.meta
          callback(data)
          totalInvoices.value = total
        })
        .catch(() => {
          pageIsLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching tickets list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {

      tableColumns,
      // Filter
      avatarText,
      perPageOptions,
      perPage,
      dataMeta,
      fetchTickets,
      searchQuery,
      totalInvoices,
      currentPage,
      filterType,
      refetchData,
      // UI
      refInvoiceListTable,
      pageIsLoading,
      isSortDirDesc,
      sortBy,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
